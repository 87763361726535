import React from 'react'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import { Link, graphql } from 'gatsby'
import Logo from '../images/logo-footer.png'

interface notfoundData {
  data: {
    wp: any
    mainMenu: any
    form: any
  }
}

const NoPageFound = ({ data }: notfoundData) => {
  const { mainMenu, wp, form } = data
  const langData = { languages: wp.languages, currentLang: 'fi', translations: [] }
  const { texts } = wp
  return (
    <Layout nav={mainMenu} lang={langData} form={form} showhero={false}>
      <Container className="bg-lightlatte -mb-14">
        <div className="w-full flex items-center h-screen flex-col text-center justify-center">
          <h1 className="text-2xl md:text-5xl break-words uppercase wordbreak mt-0 md:mt-10">
            {texts.gwNotfoundTitlefi || 'Valitettavasti Etsimääsi sivua ei löydy'}
          </h1>
          <div className="font-bold text-center">
            <p className="mt-6 md:mt-20 text-lg md:text-3xl mb-2">
              {texts.gwNotfoundTextfi || 'Varmista, että kirjoitit URL-osoitteen oikein.'}
            </p>
            <Link className="text-sm sm:text-lg md:text-3xl text-blue" to="/">
              {texts.gwNotfoundLinkTextfi || 'Klikkaa tästä takaisin etusivulle'}
            </Link>
            <div className="mt-10 md:mt-20 w-full flex items-center justify-center">
              <img alt="Kalevalaseura logo" className="w-20 md:w-32 h-auto" src={Logo} />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const SearchQuery = graphql`
  query notfoundPage {
    locales: allLocale(filter: { language: { eq: "fi" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wp {
      ...allLang
      texts: crbThemeOptions {
        gwNotfoundTextfi
        gwNotfoundLinkTextfi
        gwNotfoundTitlefi
      }
    }
    form: wpGravityFormsForm(formId: { eq: 1 }) {
      ...GravityFormFields
    }
    mainMenu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
  }
`

export default NoPageFound
